import React from 'react';
import loadable from '@loadable/component';

import { Layout } from '@components/layout';

const Candidatos = loadable(() => import('@screens/postar_plantao/Candidatos'));

function Index(props) {
  return (
    <Layout>
      <Candidatos {...props} />
    </Layout>
  );
}

export default Index;
